class Video {

    constructor(element) {

        this.element = element;
        this.player = this.element.querySelector('video');

        if (this.parseSources()) {
            this.initPlayer();
        }

    }

    /**
     * Init the player instance
     */
    initPlayer() {

        this.sourceData = getClosestVideoSource(this.sources);

        this._addMediaSources();

    }

    parseSources() {

        try {

            this.sources = JSON.parse(this.player.dataset.sources);

            if (typeof this.sources === 'object') {
                return true;
            } else {
                return false;
            }

        } catch (e) {
            console.error('Failed to parse sources. Are you sure this is an object?');
            return false;
        }

    }

    _addMediaSources() {

        this.sourceData.source.forEach(source => {
            this.source = document.createElement('source');
            this.source.type = source.type;
            this.source.src = source.url;
            this.player.appendChild(this.source);
        });

    }

}

function getClosestVideoSource(sources) {

    const windowWidth = window.innerWidth;
    let closestSource = null;

    try {

        sources.map(el => {
            if (closestSource == null || Math.abs(el.size - windowWidth) < Math.abs(closestSource.size - windowWidth)) {
                closestSource = el;
            }
        });

        return closestSource;

    } catch (e) {
        console.error('Failed to find closest source. Are you sure this is an object?');
        return closestSource;
    }

}

export default Video;