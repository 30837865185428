// import { removeChildren } from '@utilities/remove-children'
// import { fetchData } from '@utilities/fetch'
// import { historyPushState } from '@utilities/historyState'
// import { parseStringToHTML } from '@utilities/html-parser'
// import { hideElement } from '@utilities/hide'
// import { switchTheme } from '@utilities/theme-switcher'
// import video from '@utilities/video'

// const JS_HOOK_WORK = '[js-hook-module-work]'
// const JS_HOOK_PROJECTLINK = '[js-hook-projectlink]'
// const JS_HOOK_FETCH = '[js-hook-fetch]'
// const JS_HOOK_HERO = '[js-hook-hero]'
// const JS_HOOK_VIDEO = '[js-hook-video]'

const work = () => {
	// const contentContainer = document.querySelector(JS_HOOK_WORK)
	// let projectLinks = document.querySelectorAll(JS_HOOK_PROJECTLINK)

	// const showProject = async (projectDocument) => {
	// 	const fetchContainer = projectDocument.querySelector(JS_HOOK_FETCH)
	// 	const workContainer = projectDocument.querySelector(JS_HOOK_WORK)
	// 	/**** Rewrite: breaks if more classes are applied to body ****/
	// 	const theme = projectDocument.body.classList.value
	// 	/*************************************************************/
	// 	const projectHTML = fetchContainer ? fetchContainer.innerHTML : workContainer.innerHTML
	// 	const hero = projectDocument.querySelector(JS_HOOK_HERO)
	// 	const heroFigure = hero ? hero.firstElementChild : null
	// 	let heroImageSRC = null

	// 	if (await supportsWebp()) {
	// 		if(heroFigure !== null) {
	// 		heroImageSRC = heroFigure.getElementsByTagName('source')[0] ? heroFigure.getElementsByTagName('source')[0].srcset : null
	// 		}
	// 	} else if (heroFigure !== null) {
	// 		heroImageSRC = heroFigure.getElementsByTagName('img')[0] ? heroFigure.getElementsByTagName('img')[0].src : null
	// 	}

	// 	const removeOnLoad = () => {
	// 		contentContainer.classList.remove('hide')
	// 	}

	// 	switchTheme(theme)

	// 	hideElement(contentContainer)

	// 	setTimeout(() => {
	// 		window.scroll(0, 0)

	// 		removeChildren(contentContainer)

	// 		contentContainer.insertAdjacentHTML('afterbegin', projectHTML)

	// 		const videoFigures = document.querySelectorAll(JS_HOOK_VIDEO)

	// 		for (let videoFigure of videoFigures) {
	// 		new video(videoFigure)
	// 		}

	// 		const videoGifSRC = videoFigures[0] ? videoFigures[0].getElementsByTagName("source")[0].src : null

	// 		/* Reset project links after fetch to get related products links */
	// 		projectLinks = document.querySelectorAll(JS_HOOK_PROJECTLINK)
	// 		projectClick()

	// 		if (videoGifSRC !== null) {
	// 		const video = document.createElement('video')
	// 		const source = document.createElement('source')

	// 		video.appendChild(source)
	// 		video.addEventListener('canplaythrough', () => removeOnLoad())

	// 		source.src = videoGifSRC

	// 		} else if (heroImageSRC !== null) {
	// 		const heroImage = new Image()

	// 		heroImage.onload = () => removeOnLoad()
	// 		heroImage.srcset = heroImageSRC

	// 		} else {
	// 		removeOnLoad()
	// 		}
	// 	}, 1000) // Time based on CSS animation length
	// }

	/* Source: https://davidwalsh.name/detect-webp */
	const supportsWebp = async () => {
		if (!self.createImageBitmap) return false;

		const webpData = 'data:image/webp;base64,UklGRh4AAABXRUJQVlA4TBEAAAAvAAAAAAfQ//73v/+BiOh/AAA=';
		const blob = await fetch(webpData).then(r => r.blob());
		return createImageBitmap(blob).then(() => true, () => false);
	}

	// const projectClick = () => {
	// 	for (const link of projectLinks) {
	// 		const url = link.href

	// 		link.addEventListener('click', async (event) => {
	// 			event.preventDefault()

	// 			const data = await fetchData(url)
	// 			const projectDocument = parseStringToHTML(data)

	// 			historyPushState(url)

	// 			showProject(projectDocument)
	// 		})
	// 	}
	// }

	// const previousPageEvent = () => {
	// 	const url = window.location.href

	// 	historyPushState(url)

	// 	window.addEventListener('popstate', async (event) => {
	// 		const data = await fetchData(event.state)
	// 		const projectDocument = parseStringToHTML(data)

	// 		showProject(projectDocument)
	// 	})
	// }

	const projectHover	= () => {
		const videoFigures = document.querySelectorAll('[js-hook-video] > video');
		for (let videoFigure of videoFigures) {
			videoFigure.addEventListener( 'mouseover', function(e) {
				this.play()
			})
			videoFigure.addEventListener( 'mouseout', function(e) {
				// this.pause()
				this.currentTime = 0
			})
		}
	}

	// projectClick()
	// projectHover()
	// previousPageEvent()
}

export default work
