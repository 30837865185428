import '@utilities/in-view';
import Events from '@utilities/events';



const projectVideos = () => {

    const videoFigures = document.querySelectorAll('[js-hook-video] > video');

    let observer = new IntersectionObserver(entries => {
		entries.forEach(entry => {
			if (entry.intersectionRatio > 0) {
				entry.target.play()
        	} else {
				entry.target.pause()
				entry.target.currentTime = 0
			}
		});
	});


	videoFigures.forEach(vid => {
		observer.observe(vid);
	});

	const videosWithAudio = document.querySelectorAll('[js-hook-mute]');
	const allAudioVideos = document.querySelectorAll('[js-hook-audio] > video');

	videosWithAudio.forEach(audvid => {
		audvid.addEventListener('click', () => {
			// Save original state:
			const video = audvid.previousElementSibling;
			let initialMutedState = video.muted;

			// Mute all videos:
			allAudioVideos.forEach(vid => {
				vid.muted = true;
				vid.nextElementSibling.innerHTML = 'unmute';
			});
			
			// Unmute the clicked video:
			if(initialMutedState){
				video.muted = false;
			} else {
				video.muted = true;
			}

			// Change label:
			if(video.muted){
				audvid.innerHTML = 'unmute';
			} else {
				audvid.innerHTML = 'mute';	
			}
		});
	});

}

export default projectVideos


