import { html } from '@utilities/dom-elements';
import smoothscroll from 'smoothscroll-polyfill';
import animateScrollTo from 'animated-scroll-to';

function loadProject(){
    let scrolltop = html.scrollTop;
    if(scrolltop < 10 && window.innerWidth >= 1024){
        smoothscroll.polyfill();
        // let sh = document.querySelector('[js-hook-title]').clientHeight+40;
        animateScrollTo(75);
    }
}

export default loadProject;

