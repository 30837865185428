'use strict';

/*------------------------------------*\
 * JS Main entry file

  ROBOTIC — Test

 \*------------------------------------*/

/*---------/
* Imports
\*--------*/
import './config';
import loadProject from '@utilities/project';
import showCase from '@utilities/showcase';
import Flickity from 'Flickity';
import moduleInit from '@utilities/module-init'
import 'lazysizes';
import '@utilities/history.js';
import '@utilities/remove-children.js';
import work from './pages/work';
import projectvideos from './pages/project';
import video from '@utilities/video';
// import '@utilities/cookie.js';
import AOS from 'AOS';

/*---------/
* Hooks
\*--------*/
const JS_HOOK_TITLE = '[js-hook-title]'
const JS_HOOK_READMORE = '[js-hook-readmore]'
const JS_HOOK_PROJECT_TEXT = '[js-hook-project-text]'
const JS_HOOK_SHOWCASE = '[js-hook-showcase]'
const JS_HOOK_PEOPLE = '[js-hook-people]'
const JS_HOOK_TALKS = '[js-hook-galleryTalks]'
const JS_HOOK_MENU = '[js-hook-menu]'
const JS_HOOK_MENU_TOGGLE = '[js-hook-menutoggle]'
const JS_HOOK_NAV_LINKS = '[js-hook-navlinks]'

/*---------/
* Inits
\*--------*/
moduleInit('[js-hook-video]', video);


AOS.init({
  // delay: 200,
  // mirror: false, // whether elements should animate out while scrolling past them
  offset: 100,
  // easing: 'ease-out-circ-special', // default easing for AOS animations
  once: true,
});

setInterval(() => {
  AOS.refresh();
}, 50);

let initPageWidth;

/*---------/
* readyState
\*--------*/

const loadCheck = () => {
    const pathname = window.location.pathname
//   const url = window.location.href

//   history.replaceState(url, null, url)

  if (document.readyState === 'complete') {
    const title = document.querySelector(JS_HOOK_TITLE)
    const readMore = document.querySelector(JS_HOOK_READMORE)
    const projectText = document.querySelector(JS_HOOK_PROJECT_TEXT)
    const showcase = document.querySelector(JS_HOOK_SHOWCASE)
    const people = document.querySelector(JS_HOOK_PEOPLE)
    const talksWorkshops = document.querySelector(JS_HOOK_TALKS)
    const menu = document.querySelector(JS_HOOK_MENU)
    const menuToggle = document.querySelector(JS_HOOK_MENU_TOGGLE)
    const navLinks = document.querySelectorAll(JS_HOOK_NAV_LINKS)

    if (pathname === '/work') work()

    if (title !== null) {
    //   setTimeout(loadProject, 1500)

      document.removeEventListener('readystatechange', loadCheck)

    }

    // Project page
    if (readMore !== null) {
      readMore.addEventListener('click', function () {
        this.remove()
        projectText.classList.add('active')
      })
      projectvideos();
    }

    if (showcase !== null) showCase()

    if (people !== null) {
      const slider = people

      new Flickity(slider, {
        cellSelector: '.person',
        cellAlign: (document.documentElement.clientWidth > 767) ? 'left' : 'left',
        wrapAround: true,
        pageDots: false,
        groupCells: false,
        imagesLoaded: true,
        initialIndex : 1,
        prevNextButtons: false,
        freeScroll: true,
        on: {
          ready: () => slider.classList.add('is-ready')
        }
      })
    }

    if (talksWorkshops !== null) {
      const slider2 = talksWorkshops

      new Flickity(slider2, {
        cellSelector: '.talkImage',
        cellAlign: 'center',
        wrapAround: true,
        pageDots: false,
        groupCells: false,
        imagesLoaded: true,
        initialIndex : 4,
        prevNextButtons: false,
        freeScroll: true,
        on: {
          ready: () => slider2.classList.add('is-ready')
        }
      })
    }

    menuToggle.addEventListener('click', () => {
      if (menu.classList.contains('active')) {
        document.querySelector("[js-hook-menutoggle] > *").classList.remove('active')
        menu.classList.remove('active')
        setTimeout(() => {
          document.documentElement.classList.remove('menu-is-open')
        }, 400);
      } else {
        document.querySelector("[js-hook-menutoggle] > *").classList.add('active')
        document.documentElement.classList.add('menu-is-open')
        setTimeout(() => {
          menu.classList.add('active')
        }, 400);
      }
    })

//  ————————————————————————————————————————————————————————————————————————————

    document.querySelectorAll('[js-hook-navlinks]').forEach(function (l) {
      l.dataset.link = l.getAttribute("href");
      l.removeAttribute("href","");

      l.addEventListener('click', () => {
        if (document.querySelector('[js-hook-module-work]') !== null) {

          if (l.attributes[0].name == "js-hook-projectlink") {
            l.classList.add('PT-work_selected');
            // setTimeout(() => {
            //   l.classList.remove('PT-work_selected');
            // }, 500);
          }
          
          document.documentElement.classList.add('PT-work_out');
          setTimeout(() => {
            // setTimeout(() => {
            //   document.documentElement.classList.remove('PT-work_out');
            // }, 500);
            window.location.href = l.dataset.link;
          }, 400);

        } else {
          document.documentElement.classList.add('PT-general_out');
          l.classList.add("chosen-page");
          setTimeout(() => {
            // setTimeout(() => {
            //   document.documentElement.classList.remove('PT-general_out');
            //   l.classList.remove("chosen-page");
            // }, 500);
            window.location.href = l.dataset.link;
          }, 400);
        }
      })
    })
  }
}

document.addEventListener('readystatechange', loadCheck);

// ——————————————————————————————————————————————————————————————————————————————————————————————————————————————
// Draggable carousels

if(document.querySelector('[js-hook-grabme]') !== null) {
  
  const slider = document.querySelector('[js-hook-grabme]');
  let slides = slider.children;
  
  let isDown = false;
  let isMoving = false;
  let startX;
  let scrollLeft;
  
  slider.addEventListener('mousedown', (e) => {
    isDown = true;
    isMoving = false;
    slider.classList.add('active');
    startX = e.pageX;
    scrollLeft = slider.scrollLeft;
  });
  
  slider.addEventListener('mouseleave', () => {
    isDown = false;
    slider.classList.remove('active');
  });
  
  slider.addEventListener('mouseup', (e) => {
    e.preventDefault();
    isDown = false;
    slider.classList.remove('active');
  });
  
  slider.addEventListener('mousemove', (e) => {
    
    if(!isDown) return;
    isMoving = true;
    e.preventDefault();
    const x = e.pageX;
    
    const walk = (x-startX); //scroll-fast
    slider.scrollLeft = scrollLeft-walk;
  });
  
  for(let i = 0; i < slides.length; i++){
    slides[i].addEventListener("click", function(e){
      if(isMoving){
        e.preventDefault();
      }
    });
  }
}

document.addEventListener('DOMContentLoaded', (event) => {
  if(document.querySelector('[js-hook-video-loader]') !== null){
    let projectVideos = document.querySelectorAll('[js-hook-video-loader] > video');
    projectVideos.forEach(function(v){
      let vs = v.dataset.src;
      v.src = vs;
    });
  }

  initPageWidth = window.innerWidth;

});

let resizeTimeout;
window.addEventListener('resize', () => {
	clearTimeout(resizeTimeout);
	resizeTimeout = setTimeout(() => {
		console.log(initPageWidth, window.innerWidth);
		if(initPageWidth < 1024 && window.innerWidth > 1024){
			window.location.reload();
		}

		if(initPageWidth >= 1024 && window.innerWidth < 1024){
			window.location.reload();
		}
	}, 500);
});

// ——————————————————————————————————————————————————————————————————————————————————————————————————————————————
// Mouse follower

if (document.querySelector('[js-hook-mousefollow]') !== null) {

  const header = document.querySelector('.home-header');
  const cursorRounded = document.querySelector('.mouse-follower');

  window.addEventListener('mousemove', (e) => {
    if (e.clientY < 100) {
      cursorRounded.children[0].classList.add('hideFollower');
    } else {
      cursorRounded.children[0].classList.remove('hideFollower');
      cursorRounded.style.display = `table`;
      cursorRounded.style.transition = ``;
      cursorRounded.style.transform = `translate3d(${e.clientX-(cursorRounded.offsetWidth/2)}px, ${e.clientY-(cursorRounded.offsetHeight/2)}px, 0)`;
    }
  })
  // const projectSections = document.querySelectorAll('.project');
  // projectSections.forEach(project => {
  //   project.addEventListener('mousemove', (e) => {
  //     cursorRounded.innerHTML = '';
  //     cursorRounded.append(project.dataset.title)
  //   })
  // })
}

// ——————————————————————————————————————————————————————————————————————————————————————————————————————————————
// Click Gallery

if (document.querySelector('[js-hook-gallery]') !== null) {
  document.querySelectorAll('[js-hook-gallery]').forEach(function (e) {
      var items = e.children;
      var galleryIndex = 0;
      var galleryTime = void 0;

      var galleryChange = function galleryChange() {
          items[galleryIndex].classList.remove('gallery-item_visible');
          galleryIndex == items.length - 1 ? galleryIndex = 0 : galleryIndex++;
          items[galleryIndex].classList.add('gallery-item_visible');
      };

      var gallerySetTimer = function gallerySetTimer() {
          galleryTime = setInterval(function () {
              galleryChange();
          }, 3000);
      };
      gallerySetTimer();
  });
}

// ——————————————————————————————————————————————————————————————————————————————————————————————————————————————
// homepage scroller

if (document.querySelector('[js-hook-test]') !== null) {

  const scale = (number, inMin, inMax, outMin, outMax) => {
    return (number - inMin) * (outMax - outMin) / (inMax - inMin) + outMin;
  }

  if (document.querySelector('[js-hook-scrollme]') !== null) {

    const speedValue = 4;
    const Gallery = document.querySelector('[js-hook-scrollme]');
    let scrollPos = 0,
        scrollPagePosition = 0,
        TheGalleryInactiveTimeout,
        inTransition = false;

    const TheGallery = {
      imageChange(usingKeys, e) {
          scrollPagePosition += 1;
          if (Math.round(scrollPagePosition) % speedValue === 0 || usingKeys == true ) {
            let scaleFactor = scale((Math.round(Math.abs(e.deltaY) * 10) / 10), 20, 300, 1, 0.1);

            document.querySelectorAll('.figure-video').forEach((item) => {
              item.style.transform = `scale(${scaleFactor.toFixed(1)})`
            })

            let scrollers = document.querySelectorAll('.project');
            scrollPos++;
            if (scrollPos == scrollers.length) { scrollPos = 0 }
            TheGallery.imageSwitch(scrollers);
            console.log("CHANGE")
          }
      },
      imageSwitch(scrollers) {
        document.querySelector('.imageActive').classList.remove('imageActive');
        scrollers[scrollPos].classList.add('imageActive');
      },
      imageScaler(e) {
        // console.log("SCALE")
        // if (scaleFactor > 0.2) { scaleFactor = 1; }
        // document.querySelectorAll('.figure-video').forEach((item) => {
          // var plusOrMinus = Math.random() < 0.5 ? -1 : 1;
          // console.log((Math.round(Math.abs(e.deltaY) * 100) / 10));
          TheGallery.imageChange(false, e);
        // })
        // if (scaleFactor == 1) {
          // TheGallery.inactiveCheck();
        // }
      },
      imageTransition(nextOrPrevious, usingKeys) {
        if (nextOrPrevious === 'next' && !inTransition) {
          scrollPagePosition += 1;
          if (Math.round(scrollPagePosition) % speedValue === 0 || usingKeys == true ) {
            let scrollers = document.querySelectorAll('.project');
            Gallery.classList.add('imageTransitioning');
            inTransition = true;
            setTimeout(() => {
              scrollPos++;
              if (scrollPos == scrollers.length) { scrollPos = 0 }
              TheGallery.imageSwitch(scrollers);
            }, "200")
            setTimeout(() => {
              Gallery.classList.remove('imageTransitioning');
              inTransition = false;
            }, "400")
          }
        } else if (nextOrPrevious === 'previous' && !inTransition){
          scrollPagePosition -= 1;
          if (Math.round(scrollPagePosition) % speedValue === 0 || usingKeys == true ) {
            let scrollers = document.querySelectorAll('.project');
            Gallery.classList.add('imageTransitioning');
            inTransition = true;
            setTimeout(() => {
              scrollPos--;
              if (scrollPos == -1) { scrollPos = scrollers.length - 1 }
              TheGallery.imageSwitch(scrollers);
            }, "200")
            setTimeout(() => {
              Gallery.classList.remove('imageTransitioning');
              inTransition = false;
            }, "400")
          }
        }
      },
      inactiveCheck() {
        TheGalleryInactiveTimeout = setTimeout(() => {
          clearTimeout(TheGalleryInactiveTimeout);
          inTransition = false;
          Gallery.classList.remove('galleryActive');
          // console.log("HEY");
          document.querySelector('.mouse-follower').children[0].classList.remove('hideFollower');
        }, "100")
      },
      setActive(){
        if (!Gallery.classList.contains('galleryActive')) {
          Gallery.classList.add('galleryActive');
          inTransition = true;
          // console.log("HEYYY")
        }
        document.querySelector('.mouse-follower').children[0].classList.add('hideFollower');
      }
    }
    
    // ————————————————————————————————————————————————————————————————————————————
    // Wheel control

    let TheWheelCheck = false;
    let TheWheelCheckTimeout;
    const TheWheel = {
      ActiveStrong(){
        clearTimeout(TheWheelCheckTimeout);
        TheWheelCheck = true;
        // console.log(TheWheelCheck);
        TheWheelCheckTimeout = setTimeout(() => {
          TheWheel.DeactiveStrong();
        }, "1000")
      },
      DeactiveStrong(){
        TheWheelCheck = false;
        // console.log(TheWheelCheck);
        clearTimeout(TheWheelCheckTimeout);
      }
    }

    window.addEventListener('wheel', (e) => {
      if (e.deltaY > 20) {
        TheWheel.ActiveStrong();
        TheGallery.setActive();
        TheGallery.imageScaler(e);
        // TheGallery.imageChange('next', false);
      } else if (e.deltaY < -20) {
        TheWheel.ActiveStrong();
        TheGallery.setActive();
        TheGallery.imageScaler(e);
        // TheGallery.imageChange('previous', false);
      }
      
      // else if (e.deltaY > 2 && e.deltaY < 60) {
      //   if (!TheWheelCheck) {
      //     TheGallery.imageTransition('next', false);
      //   }
      // } else if (e.deltaY < -2 && e.deltaY > -60) {
      //   if (!TheWheelCheck) {
      //     TheGallery.imageTransition('previous', false);
      //   }
      // }
    });

    // ————————————————————————————————————————————————————————————————————————————
    // Keyboard control

    const key = {
      left: 37, up: 38, right: 39, down: 40
    }

    document.onkeydown = function (e) {
      switch (e.keyCode) {
        case key.left:
          TheGallery.imageTransition('previous', true);
          break;
        case key.up:
          TheGallery.imageTransition('previous', true);
          break;
        case key.right:
          TheGallery.imageTransition('next', true);
          break;
        case key.down:
          TheGallery.imageTransition('next', true);
          break;
        default:
          return;
      }
    };

    // ————————————————————————————————————————————————————————————————————————————
    // Touch control

    window.addEventListener('touchstart', (e) => {
      console.log(e.touches[0].clientY)
    })
    window.addEventListener('touchend', (e) => {
      console.log(e.changedTouches[0].clientY)
    })
  }  
}