function showCase(){
    let active_slide = 0;
    let num_slides = document.querySelector('[js-hook-showcase]').getAttribute('data-slides');
    console.log(num_slides);
    setInterval(function(){
        let last_slide = active_slide;
        active_slide++;
        if(active_slide >= num_slides){
            active_slide = 0;
        }
        document.querySelector('[js-hook-case-'+last_slide+']').classList.remove('active');
        document.querySelector('[js-hook-case-'+active_slide+']').classList.add('active');
    }, 4500);
}

export default showCase;